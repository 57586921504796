import React, { type ComponentProps } from 'react'
import {
  FormControl,
  Stack,
  TextField,
  type FormControlProps,
  type TextFieldProps,
} from '@mui/material'

import { FakeLabel } from 'src/components/atoms/FakeLabel/FakeLabel'
import FormErrorText from 'src/components/atoms/FormErrorText/FormErrorText'
import Tooltip from 'src/components/atoms/Tooltip'

export type FormTextInputProps = TextFieldProps & {
  errorText?: string | boolean
  tooltipText?: string
  formControlProps?: FormControlProps
  labelProps?: ComponentProps<typeof FakeLabel>
}

const FormTextInput = React.forwardRef(
  (
    {
      errorText,
      helperText,
      tooltipText,
      label,
      fullWidth = true,
      formControlProps,
      labelProps,
      slotProps,
      ...props
    }: FormTextInputProps,
    ref,
  ) => (
    <FormControl
      size={props.size}
      disabled={props.disabled}
      fullWidth={fullWidth}
      {...formControlProps}
    >
      {label && <FakeLabel {...labelProps}>{label}</FakeLabel>}
      <Stack direction="row" spacing={1} alignItems="center">
        <TextField
          inputRef={ref}
          error={!!errorText}
          fullWidth={fullWidth}
          slotProps={{
            ...slotProps,
            htmlInput: {
              autofill: 'off',
              ...slotProps?.htmlInput,
            },
          }}
          {...props}
          helperText={
            (errorText ?? helperText) && (
              <FormErrorText
                error={!!errorText}
                helperText={errorText ?? helperText}
              />
            )
          }
        />
        <Tooltip text={tooltipText} />
      </Stack>
    </FormControl>
  ),
)

export default FormTextInput
